import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import userReducer from "./features/userSlice";
import checkoutReducer from "./features/checkoutSlice";
import botsReducer from "./features/botsSlice";
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

  reducer: {
    user: userReducer,
    checkout: checkoutReducer,
    bots: botsReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
