import React from "react";

function FAQ() {
  const questions = [
    {
      question: "What should my data look like?",
      answer:
        "You can upload one or multiple files in 1 datastore (PDF, CSV, JSON, Text, PowerPoint, Word, Excel), or add a link to your website to be scraped. 1 chatbot is associated with 1 datastore.",
    },
    {
      question: "Does it use ChatGPT ?",
      answer:
        "Yes, your chatbot uses ChatGPT (gpt-4). We are planning to support other models in the future.",
    },
    {
      question: "Where is my data stored ?",
      answer:
        "The content of the document is hosted on secure AWS servers in Europe.",
    },
    {
      question: "Does it support other languages?",
      answer:
        "Yes, Cere DocBot supports about 90 languages. You can have your sources in any language and ask it questions in any language.",
    },
    {
      question: "How can I add my chatbot to my website?",
      answer:
        "You can embed an iframe or add a chat bubble to the bottom right/left of your website.",
    },
    {
      question: "Can I give my chatbots instructions?",
      answer:
        "Yes, you can edit the base prompt and give your chatbot a name, personality traits and instructions on how to answer questions ex.",
    },
  ];

  return (
    <div className="flex flex-col space-y-4 w-7/12 mx-auto">
      <div className="flex flex-col space-y-4">
        <h1 className="text-white text-2xl font-bold">
          Frequently asked questions
        </h1>
        <p className="text-gray-300 text-base font-medium w-1/2">
          Have a different question and can’t find the answer you’re looking
          for? Reach out to our support team by sending us an email and we’ll
          get back to you as soon as we can.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-x-2 gap-y-3">
        {questions.map((item, index) => (
          <div key={index} className="flex flex-col space-y-2">
            <h1 className="text-white text-lg font-semibold">
              {item.question}
            </h1>
            <p className="text-gray-300 text-base font-medium">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
