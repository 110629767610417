import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// For further development convert the object in the init function to JSON file.
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          pages: {},
          components: {
            forms: {
              login: {
                header: "Welcome to Cere DocBot",
                inputEmail: "Email address",
                inputPwd: "Password",
                placeholder1: "Password",
                text2: "Forgot password?",
                text3: "Do not have an account yet?",
                button: {
                  btnSubmit: "Sign in",
                  google: "Sign in with Google"
                },
                validation: {
                  input1: "Please enter your email address!",
                  input1format: "Email format is not correct!",
                  input2: "Please enter your password!",
                  input2min: "Your password must be at least 8 characters",
                  input2max: "Your password must be at most 25 characters",
                  input3: ""
                }
              },
              register: {
                header: "Sign up to CereMeet",
                inputEmail: "Email Address",
                inputName: "Fullname",
                inputPwd: "Password",
                inputConPwd: "Confirm Password",
                placeholder1: "Your Password",
                placeholder2: "Re-enter password",
                button: {
                  btnSubmit: "Sign up",
                  google: "Sign up with Google"
                },
                text1: "Have an account?",
                validation: {
                  input1: "Please enter your name!",
                  input2: "Please enter your email address!",
                  input2format: "Email format is not correct!",
                  input3: "Please enter your password!",
                  input3min: "Your password must be at least 8 characters",
                  input3max: "Your password must be at most 25 characters",
                  input4: "Please re-enter your password",
                  input5: "Passwords do not match!",
                  input6: ""
                }
              },
              forgot: {
                header: "Get Recovery Code",
                inputEmail: "Email",
                inputPwd: "Password",
                inputConPwd: "Confirm Password",
                placeholder1: "Your Password",
                placeholder2: "Code",
                button: {
                  btnSubmit: "Submit"
                },
                header2: "Reset Password",
                text1: "Reset Password"
              },
              contact: {
                text1: "Name",
                text2: "Email",
                text3: "Message",
                text4: "Your Message",
                button: "Submit",
                validation: {
                  input3: "Please enter your message!"
                }
              }
            },
            pricing: {
              enterprise: {
                text1: "5 bots for different purposes",
                text2: "File upload limited to 50 mb/file",
                text3: "Web widget for 10 domains",
                text4: "Monthly 10000 requests to the bots",
                text5: "Customer support",
                text6: "Unlimited data procesing",
                buttonText: "Contact Us"
              },
              pro: {
                text1: "Single bot with standalone webpage",
                text2: "File upload limited to 25 mb/file",
                text3: "Web widget for 1 domain",
                text4: "Monthly 250 questions to the bot",
                text5: "Unlimited data processing",
                buttonText: "Subscribe"
              }
            },

            errors: {
              title: "Error",
              404: "Page cannot be found",
              1001: "Address with that ID not found",
              1002: "You are not authorized to delete this address",
              1003: "You are not authorized to display this address",
              1004: "Address with that name already exist",
              1005: "Address with that title already exist",
              1101: "Validation error",
              2001: "Invalid email or password",
              2002: "You are not verified, check your email to verify your account",
              2003: "Invalid email or password",
              2004: "Invalid email",
              2006: "Could not verify email",
              2007: "Could not refresh access token",
              2008: "User with that email already exist",
              2009: "Your password reset code is not valid.",
              3001: "There was an error sending email, please try again",
              4001: "Meeting with that ID not found",
              5001: "AI quota exceeded",
              5002: "Conversation limit exceeded",
              5003: "Bot with that ID not found",
              5004: "AI response error",
              5005: "The question violates our terms of service",
              6001: "User with that ID not found",
              6002: "Username already exists",
              7001: "You are not logged in",
              7002: "Invalid token or user doesn't exist",
              7003: "Invalid token or session has expired",
              7004: "Session has expired or user doesn't exist",
              9001: "You already have a subscription",
              9002: "You dont have a subscription",
              100001: "System error",
              100310: "Invalid token.",
              100311: "Access token expired.",
              100312: "Authentication error.",
              100300: "You do not have access to merchant information.",
              200301: "Email is required.",
              200302: "Email should include less than {0} characters.",
              200303: "Email format is invalid.",
              200304: "Identity number is required.",
              200305:
                "Identity number should include less than {0} characters.",
              200306:
                "Identity number should include more than {0} characters.",
              200310: "Phone number is invalid.",
              200311: "Phone number should include less than {0} characters.",
              200320: "Pagination request not valid.",
              200500: "Product name is required.",
              200501: "At least one parameter is required.",
              200600: "Pricing plan name is required.",
              200601: "Pricing plan price is required.",
              200602: "Pricing plan price is invalid.",
              200603: "Payment Interval is required.",
              200604: "Payment Interval is invalid.",
              200605: "currencyCode is required.",
              200606: "PlanPaymentType is required",
              200607: "PlanPaymentType is invalid",
              200608: "Trial period is invalid.",
              200610: "Reference code is required.",
              200611: "Payment interval count is invalid.",
              200700: "Customer name is required.",
              200701: "Customer surname is required.",
              200702: "Customer phone number is required.",
              200703: "Billing address is required.",
              200710: "At least one parameter is required.",
              200711: "Reference code is required",
              200800: "Address is required.",
              200801: "Address should include less than {0} characters.",
              200802: "Contact Name is required.",
              200803: "Contact Name should include less than {0} characters.",
              200804: "City is required.",
              200805: "City should include less than {0} characters.",
              200806: "Country is required.",
              200807: "Country should include less than {0} characters",
              200808: "Zip Code should include less than {0} characters.",
              200900: "Reference code is required.",
              200901: "Reference code is required.",
              200902: "Subscription status is invalid.",
              201550: "Price is required.",
              201551: "Price should be more than zero.",
              201000: "Product is not found.",
              201001: "Product already exists.",
              201002: "Active product is not found.",
              201500: "Pricing plan is not found.",
              201501: "Pricing plan already exists.",
              201052: "Active pricing plan is not found",
              201053: "Pricing plan is not suitable to be deleted",
              201054: "Pricing plan is not active.",
              201100: "Customer is not found.",
              201101: "Customer already exists.",
              201102: "Customer is not active.",
              201103: "Customer should have card.",
              201400: "Subscription is not found.",
              201401: "Subscription is not allowed to activate.",
              201402: "Subscription is not allowed to upgrade.",
              201403: "Subscription is not allowed to cancel.",
              201404: "Subscription has already same pricing plan.",
              201405:
                "Subscription upgrade is not allowed to different product.",
              201406:
                "Subscription can not be upgraded to a pricing plan with different payment interval.",
              201450: "Subscription order is not found.",
              200750: "Card is required",
              200751: "Card should be allowed to be registered.",
              200903: "Subscription reference code is required.",
              200905: "Subscription status is invalid for card update.",
              201553: "Card is not belong to customer.",
              201451: "Subscription payment not suitable for retry.",
              201600: "Checkoutform is not found.",
              201601: "Checkoutform not completed.",
              201700: "Recurring payment attempt not found!",
              201900: "Currency is not found."
            },
            successes: {
              title: "Success",
              payment: {
                text1: "Profile info updated.",
                text2: "Address info updated.",
                text3: "Payment received."
              },
              login: {
                text1: "You are logged in."
              },
              register: {
                text1: "Profile created."
              },
              forgot: {
                text1: "Renewal code sent to your email address.",
                text2: "Your password has been renewed."
              },
              contact: {
                text1: "Message received."
              }
            }
          }
        }
      },
      tr: {
        translation: {
          pages: {},
          components: {
            forms: {
              login: {
                header: "Cere DocBot'a Hoş Geldiniz",
                inputEmail: "Email adres",
                inputPwd: "Şifre",
                text2: "Şifrenizi mi unuttunuz?",
                text3: "Halen bir hesabınız yok mu?",
                button: {
                  btnSubmit: "Giriş Yap",
                  google: "Google ile Giriş Yap"
                },
                validation: {
                  input1: "Lütfen e-posta adresinizi giriniz!",
                  input1format: "E-posta formatı doğru değil!",
                  input2: "Lütfen şifrenizi girin!",
                  input2min: "Şifreniz en az 8 karakter olmalıdır",
                  input2max: "Şifreniz en fazla 25 karakter olmalıdır",
                  input3: ""
                }
              },
              register: {
                header: "Ceremeet'e Kayıt Ol",
                inputEmail: "E-posta",
                inputPwd: "Şifre",
                inputName: "Tam adınız",
                inputConPwd: "Şifrenizi Doğrulayın",
                placeholder1: "Şifreniz",
                placeholder2: "Tekrar Şifreniz",
                button: {
                  btnSubmit: "Kayıt ol",
                  google: "Google ile Kayıt ol"
                },
                text1: "Kayıtlı mısınız?",
                validation: {
                  input1: "Lütfen adınızı giriniz!",
                  input2: "Lütfen e-posta adresinizi giriniz!",
                  input2format: "E-posta formatı doğru değil!",
                  input3: "Lütfen şifrenizi girin!",
                  input3min: "Şifreniz en az 8 karakter olmalıdır",
                  input3max: "Şifreniz en fazla 25 karakter olmalıdır",
                  input4: "Lütfen şifrenizi tekrar giriniz",
                  input5: "Şifreler uyuşmuyor!",
                  input: ""
                }
              },
              forgot: {
                header: "Doğrulama Kodu Al",
                inputEmail: "E-posta",
                placeholder1: "Şifreniz",
                button: {
                  btnSubmit: "Gönder"
                },
                inputPwd: "Şifreniz",
                inputConPwd: "Şifrenizi Doğrulayın",
                placeholder2: "Kod",
                header2: "Yeni Şifre",
                text1: "Doğrulama Kodu"
              },
              contact: {
                text1: "Adınız",
                text2: "E-posta Adresi",
                text3: "Mesajınız",
                text4: "Yeni ürünleriniz hakkıda bilgi almak istiyorum.",
                button: "Gönder",
                validation: {
                  input3: "Lütfen mesajınızı giriniz!"
                }
              }
            },
            pricing: {
              enterprise: {
                text1: "Farklı amaçlar için 5 bot",
                text2: "Dosya yükleme 50 mb/dosya ile sınırlıdır",
                text3: "10 alan adı için web widget'ı",
                text4: "Botlara aylık 10000 istek",
                text5: "Müşteri desteği",
                text6: "Sınırsız veri işleme",
                buttonText: "Bize Ulaşın"
              },
              pro: {
                text1: "Bağımsız web sayfası ile tek bot",
                text2: "Dosya yükleme 25 mb/dosya ile sınırlıdır",
                text3: "1 alan adı  için web widget'ı",
                text4: "Bot için aylık 250 soru",
                text5: "Sınırsız veri işleme",
                buttonText: "Abone Ol"
              }
            },

            errors: {
              title: "Hata",
              404: "Sayfa bulunamadı.",
              1001: "Bu id'ye sahip bir adres zaten mevcut",
              1002: "Bu adresi silme yetkiniz yok",
              1003: "Bu adresi görüntüleme yetkiniz yok",
              1004: "Bu isme sahip bir adres zaten var",
              1005: "Bu başlığa sahip bir adres zaten var",
              1101: "Doğrulama hatası",
              2001: "Geçersiz eposta veya şifre",
              2002: "Hesabınız doğrulanmamış, lütfen doğrulama e-postanızı kontrol edin",
              2003: "Hatalı eposta veya şifre",
              2004: "Geçersiz eposta veya şifre",
              2006: "Eposta doğrulanamadı",
              2007: "Erişim anahtarı yenilenemedi",
              2008: "Bu epostaya sahip bir kullanıcı mevcut",
              2009: "Eposta yenileme kodunuz geçerli değil",
              3001: "Eposta gönderirken bir sorunla karşılaşıldı, lütfen tekrar deneyin",
              4001: "Bu ID ile bir toplantı bulunamadı",
              5001: "Yapay zeka kotanızı doldurdunuz",
              5002: "Diyalog limitine ulaşıldı",
              5003: "Bu ID ile bir bot bulunamadı",
              5004: "Yapay zeka cevabı alınamadı",
              5005: "Soru kullanıcı politikamızı ihlal ediyor",
              6001: "Bu ID ile bir kullanıcıya rastlanmadı",
              6002: "Kullanıcı adı daha önce alınmış",
              7001: "Giriş yapılamadı",
              7002: "Geçersiz anahtar veya kullanıcı yok",
              7003: "Geçersiz anahtar veya oturum kapatılmış",
              7004: "Geçersiz anahtar veya oturum kapatılmış",
              9001: "Zaten bu üyeliğe sahipsiniz",
              9002: "Üyeliğiniz bulunmamakta",
              100001: "Sistem hatası",
              100310: "Geçersiz token isteği.",
              100311: "Access tokenın geçerlilik süresi doldu.",
              100312: "Kimlik doğrulama hatası!",
              100300: "Üye işyeri bilgisine erişim izniniz yok.",
              200301: "Email zorunlu alandır.",
              200302: "Email bilgisi en fazla {0} karakter olmalıdır.",
              200303: "Email formatı hatalı.",
              200304: "Kimlik numarası zorunlu alandır.",
              200305: "Kimlik numarası en fazla {0} karakter olmalıdır",
              200306: "Kimlik numarası en az {0} karakter olmalıdır.",
              200310: "Geçersiz telefon numarası.",
              200311: "Telefon numarası en fazla {0} karakter olmalıdır.",
              200320: "Geçersiz Sayfalama isteği.",
              200500: "Ürün adı zorunlu alandır.",
              200501: "En az bir parametrenin gönderilmesi zorunludur.",
              200600: "Ödeme planı zorunlu alandır.",
              200601: "Fiyat bilgisi zorunlu alandır.",
              200602: "Geçersiz fiyat bilgisi.",
              200603: "Ödeme periyodu zorunlu alandır.",
              200604: "Geçersiz ödeme periyodu.",
              200605: "Döviz tipi zorunlu alandır.",
              200606: "Ödeme tipi zorunlu alandır.",
              200607: "Geçersiz ödeme tipi.",
              200608: "Geçersiz deneme süresi.",
              200610: "Referans kodu zorunlu alandır.",
              200611: "Geçersiz ödeme aralığı.",
              200700: "Müşteri adı zorunlu alandır.",
              200701: "Müşteri soyadı zorunlu alandır.",
              200702: "Müşteri telefon numarası zorunlu alandır.",
              200703: "Fatura adresi zorunlu alandır.",
              200710: "En az bir parametrenin gönderilmesi zorunludur.",
              200711: "Referans kodu zorunlu alandır.",
              200800: "Adres zorunlu alandır.",
              200801: "Adres bilgisi en az {0} karakter olmalıdır.",
              200802: "İletişim adı zorunlu alandır.",
              200803: "İletişim adı en az {0} karakter olmalıdır.",
              200804: "Şehir zorunlu alandır.",
              200805: "Şehir en az {0} karakter olmalıdır.",
              200806: "Ülke zorunlu alandır.",
              200807: "Ülke en az {0} karakter olmalıdır.",
              200808: "Posta kodu en az {0} karakter olmalıdır.",
              200900: "Referans kodu zorunlu alandır.",
              200901: "Referans kodu zorunlu alandır.",
              200902: "Geçersiz abonelik durumu.",
              201550: "Fiyat bilgisi gönderilmesi zorunludur",
              201551: "Fiyat bilgisi sıfırdan büyük olmalıdır.",
              201000: "Ürün bilgisi bulunamadı.",
              201001: "Ürün zaten var.",
              201002: "Aktif ürün bulunamadı.",
              201500: "Ödeme planı bulunamadı.",
              201501: "Ödeme planı zaten var.",
              201052: "Ödeme planı aktif değil.",
              201053: "Ödeme planı silinmek için uygun değil.",
              201054: "Ödeme planı aktif değil.",
              201100: "Müşteri bulunamadı.",
              201101: "Müşteri zaten var.",
              201102: "Müşteri aktif değil.",
              201103: "Müşterinin kartı olmalı.",
              201400: "Abonelik bulunamadı.",
              201401: "Bu abonelik aktif edilemez.",
              201402: "Bu abonelik yükseltilemez.",
              201403: "Bu abonelik iptal edilemez.",
              201404: "Abonelik aynı ödeme planına sahip.",
              201405:
                "Abonelik başka bir ürüne ait ödeme planına yükseltilemez.",
              201406:
                "Abonelik farklı ödeme sıklığına sahip ödeme plana yükseltilemez.",
              201450: "Ödeme bulunamadı.",
              200750: "Kart verisi gönderilmesi zorunludur.",
              200751: "Kartın kaydedilmesine izin verilmesi zorunludur.",
              200903: "Abonelik referens kodu zorunlu alandır.",
              200905: "Abonelik durumu kart güncelleme için uygun değil.",
              201553: "Kart müşteriye ait değil.",
              201451: "Ödeme tekrar denemek için uygun değil.",
              201600: "Ödeme formu bulunamadı.",
              201601: "Ödeme formu tamamlanmamış.",
              201700: "Yinelenen ödeme girişimi bulunamadı!",
              201900: "Döviz cinsi bulunamadı."
            }
          }
        }
      }
    }
  });

export default i18n;
