import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import {
  setSubscriberAddress,
  setSubscriberPhone,
  setSubscriberCity,
  setSubscriberCountry,
  setSubscriberFirstName,
  setSubscriberLastName,
  setSubscriberPaymentAddressCheck,
  setSubscriberPostCode
} from "../redux/features/checkoutSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Label } from "@mui/icons-material";

export default function AddressForm() {
  const [valid, setValid] = useState(true);

  const handleChange = (value: string) => {
    dispatch(setSubscriberPhone(value));
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,10}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const dispatch = useAppDispatch();
  const subsFirstname = useSelector(
    (state: RootState) => state.checkout.subscriberFirstName
  );
  const subsLastname = useSelector(
    (state: RootState) => state.checkout.subscriberLastName
  );
  const subsAddress = useSelector(
    (state: RootState) => state.checkout.subscriberAddress
  );
  const phone = useSelector(
    (state: RootState) => state.checkout.subscriberPhone
  );
  const city = useSelector((state: RootState) => state.checkout.city);
  const postCode = useSelector((state: RootState) => state.checkout.postCode);
  const country = useSelector((state: RootState) => state.checkout.country);
  const paymentAddressCheck = useSelector(
    (state: RootState) => state.checkout.paymentAddressCheck
  );

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={subsFirstname}
            onChange={(e) => dispatch(setSubscriberFirstName(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={subsLastname}
            onChange={(e) => dispatch(setSubscriberLastName(e.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            value={subsAddress}
            onChange={(e) => dispatch(setSubscriberAddress(e.target.value))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            value={city}
            onChange={(e) => dispatch(setSubscriberCity(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            value={postCode}
            onChange={(e) => dispatch(setSubscriberPostCode(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
            value={country}
            onChange={(e) => dispatch(setSubscriberCountry(e.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <TextField
            required
            id="phonenumber"
            name="phonenumber"
            label="Phone Number"
            fullWidth
            autoComplete="tel"
            variant="standard"
            value={phone}
            onChange={(e) => dispatch(setSubscriberPhone(e.target.value))}
          /> */}
          <label htmlFor="" className="text-sm">
            Phone Number*
          </label>
          <PhoneInput
            specialLabel="Phone Number"
            country={"tr"}
            autoFormat={false}
            value={phone}
            onChange={handleChange}
            inputProps={{
              required: true
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="saveAddress"
                checked={paymentAddressCheck}
                onChange={(e) => {
                  dispatch(setSubscriberPaymentAddressCheck(e.target.checked));
                }}
              />
            }
            label="Use this address for payment details"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
