import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 5, color: "white" }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        target="_blank"
        rel="noopener"
        href="https://cerebrumtechnologies.com/"
      >
        Cerebrum Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [1, 2]
      }}
    >
      <Grid container spacing={1} justifyContent="space-evenly">
        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
          className="cursor-pointer"
        >
          Pricing
        </Typography>
        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
          className="cursor-pointer"
        >
          Docs
        </Typography>
        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
          className="cursor-pointer"
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
          className="cursor-pointer"
        >
          Terms of Service
        </Typography>
        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
          className="cursor-pointer"
        >
          Affiliates
        </Typography>
      </Grid>
      <Copyright />
    </Container>
  );
}
