import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { useIsAuthenticated } from "react-auth-kit";
import LoginPage from "./pages/LoginPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CheckoutPage from "./pages/CheckoutPage";
import ContactUsPage from "./pages/ContactUsPage";
import BotOperationsPage from "./pages/BotOperationsPage";

function App() {
  const PrivateRoute = ({ Component }: any) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? Component : <Navigate to="/login" />;
  };
  const clientId = process.env.REACT_APP_GOOGLE_CLIENTID ?? "";

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/checkout"
            element={<PrivateRoute Component={<CheckoutPage />} />}
          />
          <Route
            path="/contactus"
            element={<PrivateRoute Component={<ContactUsPage />} />}
          />
          <Route
            path="/managebot"
            element={<PrivateRoute Component={<BotOperationsPage />} />}
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
