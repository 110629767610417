import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormLabel, Radio, RadioGroup } from "@mui/material";
import { setSubscriptionType } from "../redux/features/checkoutSlice";
import { setUserName } from "../redux/features/userSlice";
import { useAppDispatch } from "../redux/store";

export default function SelectMembership() {
  const dispatch = useAppDispatch();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Subscriptions
      </Typography>
      <Grid
        container
        spacing={3}
        className="flex items-center justify-start px-8 pt-5"
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="pro-month"
            name="radio-buttons-group"
            onChange={(e) => {
              dispatch(setSubscriptionType(e.target.value));
            }}
          >
            <FormControlLabel
              value="pro-month"
              control={<Radio />}
              label="Pro-Monthly"
            />
            <FormControlLabel
              value="pro-year"
              control={<Radio />}
              label="Pro-Yearly"
            />
            <FormControlLabel
              value="enterprise"
              control={<Radio />}
              label="Enterprise"
              onClick={() => setSubscriptionType("sdasd")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
