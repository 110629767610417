import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import SelectMembership from "./SelectMembership";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { setUserName } from "../redux/features/userSlice";

const steps = [
  "Subscription",
  "Shipping address",
  "Payment details",
  "Review your order"
];

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <SelectMembership />;
    case 1:
      return <AddressForm />;
    case 2:
      return <PaymentForm />;
    case 3:
      return <Review />;
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useAppDispatch();
  const subscriptionType = useSelector(
    (state: RootState) => state.checkout.subscriptionType
  );
  const subsName = useSelector(
    (state: RootState) => state.checkout.subscriberFirstName
  );

  const subsLastName = useSelector(
    (state: RootState) => state.checkout.subscriberLastName
  );
  const subsAddress = useSelector(
    (state: RootState) => state.checkout.subscriberAddress
  );
  const subsCity = useSelector((state: RootState) => state.checkout.city);
  const subsPostCode = useSelector(
    (state: RootState) => state.checkout.postCode
  );
  const subsCountry = useSelector((state: RootState) => state.checkout.country);
  const phone = useSelector(
    (state: RootState) => state.checkout.subscriberPhone
  );
  const nameOnCard = useSelector(
    (state: RootState) => state.checkout.nameOnCard
  );
  const cardNumber = useSelector(
    (state: RootState) => state.checkout.cardNumber
  );
  const expireDate = useSelector(
    (state: RootState) => state.checkout.expiryDate
  );
  const cvv = useSelector((state: RootState) => state.checkout.cvv);
  const [error, setError] = React.useState<null | string>(null);

  const handleNext = () => {
    if (activeStep === 0 && subscriptionType !== null) {
      setActiveStep(activeStep + 1);
      setError(null);
    } else {
      setError("Please select a subscription type!");
    }

    if (activeStep === 1) {
      if (
        activeStep === 1 &&
        (subsName === "" ||
          subsLastName === "" ||
          subsAddress === "" ||
          subsCity === "" ||
          subsPostCode === "" ||
          subsCountry === "" ||
          phone === "" ||
          phone?.length < 3)
      ) {
        setError("Please fill the must places!");
      } else {
        setError(null);
        setActiveStep(activeStep + 1);
      }
    }

    if (activeStep === 2) {
      if (
        activeStep === 2 &&
        (nameOnCard === "" ||
          cardNumber === "" ||
          cvv === "" ||
          expireDate === "")
      ) {
        setError("Please fill the must places!");
      } else {
        setError(null);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setError(null);

    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Place order" : "Next"}
                </Button>
              </Box>
              {error && (
                <div>
                  <h1>{error}</h1>
                </div>
              )}
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
