import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const products = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99"
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45"
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51"
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11"
  },
  { name: "Shipping", desc: "", price: "Free" }
];
const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
const payments = [
  { name: "Card type", detail: "Visa" },
  { name: "Card holder", detail: "Mr John Smith" },
  { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Expiry date", detail: "04/2024" }
];

export default function Review() {
  const subscriptionType = useSelector(
    (state: RootState) => state.checkout.subscriptionType
  );
  const subsName = useSelector(
    (state: RootState) => state.checkout.subscriberFirstName
  );

  const subsLastName = useSelector(
    (state: RootState) => state.checkout.subscriberLastName
  );
  const subsAddress = useSelector(
    (state: RootState) => state.checkout.subscriberAddress
  );
  const subsCity = useSelector((state: RootState) => state.checkout.city);
  const subsPostCode = useSelector(
    (state: RootState) => state.checkout.postCode
  );
  const subsCountry = useSelector((state: RootState) => state.checkout.country);
  const phone = useSelector(
    (state: RootState) => state.checkout.subscriberPhone
  );
  const nameOnCard = useSelector(
    (state: RootState) => state.checkout.nameOnCard
  );
  const cardNumber = useSelector(
    (state: RootState) => state.checkout.cardNumber
  );
  const expireDate = useSelector(
    (state: RootState) => state.checkout.expiryDate
  );
  const cvv = useSelector((state: RootState) => state.checkout.cvv);

  const expireDateConverter = (date: any) => {
    const year = date.split("-")[0];
    const month = date.split("-")[1];
    return `${month}/${year}`;
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <ListItem key={subscriptionType} sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={subscriptionType}
            secondary={subscriptionType}
          />
          <Typography variant="body2">{"$180.00"}</Typography>
        </ListItem>

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            $180.00
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>
            {subsName} {subsLastName}
          </Typography>
          <Typography gutterBottom>
            {subsAddress} {subsCity} {subsPostCode} {subsCountry}{" "}
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            <React.Fragment key={"Card holder"}>
              <Grid item xs={6}>
                <Typography gutterBottom>{"Card holder"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{nameOnCard}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment key={"Card Number"}>
              <Grid item xs={6}>
                <Typography gutterBottom>{"Card Number"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{cardNumber}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment key={"Expiry Date"}>
              <Grid item xs={6}>
                <Typography gutterBottom>{"Expiry Date"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {expireDateConverter(expireDate)}
                </Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment key={"Phone Number"}>
              <Grid item xs={6}>
                <Typography gutterBottom>{"Phone Number"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{phone}</Typography>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
