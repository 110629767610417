import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { GiBreakingChain } from "react-icons/gi";
import { FaClock } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";
function Information() {
  return (
    <div className="flex flex-col w-2/3 mx-auto pl-10">
      <div className="flex justify-start">
        <div className="flex flex-col space-y-4 w-1/2 justify-start ">
          <div className="flex flex-col">
            <h3 className="text-[#E11D48] text-base font-medium">
              Your second brain
            </h3>
            <h1 className="text-white text-2xl font-bold">
              Talk with your Data
            </h1>
          </div>
          <div className="flex space-x-2 items-start">
            <FaCloudUploadAlt size={20} color="#E11D48" />
            <div className="flex flex-col space-y-2">
              <h3 className="text-white text-lg font-semibold">
                Load data from any source
              </h3>
              <p className="text-gray-300 text-base font-medium">
                Easily upload documents you'd like to chat with.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 items-start">
            <BsFillLightningChargeFill size={20} color="#E11D48" />
            <div className="flex flex-col space-y-2">
              <h3 className="text-white text-lg font-semibold">
                Instant answers
              </h3>
              <p className="text-gray-300 text-base font-medium">
                Ask questions, extract information, and summarize documents with
                AI.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 items-start">
            <GiBreakingChain size={20} color="#E11D48" />
            <div className="flex flex-col space-y-2">
              <h3 className="text-white text-lg font-semibold">
                Sources included
              </h3>
              <p className="text-gray-300 text-base font-medium">
                Every response is backed by sources extracted from the uploaded
                document..
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex flex-col space-y-4 w-1/2 justify-start ">
          <div className="flex flex-col">
            <h3 className="text-[#E11D48] text-base font-medium">
              For Customer Support
            </h3>
            <h1 className="text-white text-2xl font-bold">
              Customer Support Made Easy
            </h1>
          </div>
          <div className="flex space-x-2 items-start">
            <FaCloudUploadAlt size={20} color="#E11D48" />
            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                Efficient Your AI chatbot can answer frequently asked questions
                and handle simple support requests, allowing your team to focus
                on providing more personalized assistance to customers.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 items-start">
            <FaClock size={20} color="#E11D48" />
            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                24/7 Support Your AI chatbot can handle customer inquiries
                around the clock, providing efficient support even when your
                team is unavailable.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 ">
            <MdContactSupport size={20} color="#E11D48" />

            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                Easy to Implement Integrating your AI chatbot onto your website
                is a breeze - simply copy and paste our code onto your site and
                start providing instant support to your visitors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-start">
        <div className="flex flex-col space-y-4 w-1/2 justify-start ">
          <div className="flex flex-col">
            <h3 className="text-[#E11D48] text-base font-medium">For Makers</h3>
            <h1 className="text-white text-2xl font-bold">
              Experience Our No-Code Platform
            </h1>
            <p className="text-white text-base font-medium">
              With our no-code platform, you can create a custom AI chatbot
              trained on your data in seconds. Use Chaindesk API to query your
              agent or to perform document retrievial
            </p>
          </div>
          <div className="flex space-x-2 items-start">
            <FaCloudUploadAlt size={20} color="#E11D48" />
            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                Easy to Use Our no-code platform allows you to create and manage
                your AI chatbots with ease, even with no technical knowledge.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 items-start">
            <FaClock size={20} color="#E11D48" />
            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                Data, APIs integrations Build highly integrated LLM agents that
                connect to data and APIs, opening up a world of possibilities
                for limitless applications.
              </p>
            </div>
          </div>
          <div className="flex space-x-2 ">
            <MdContactSupport size={20} color="#E11D48" />

            <div className=" w-5/6">
              <p className="text-gray-300 text-base font-medium">
                Seamless Integrations Integrate your AI chatbot onto Slack,
                Whatsapp and other platforms with ease, and start engaging with
                your audience on the channels they prefer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
