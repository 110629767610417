import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CreateButton from "./CreateButton";
import {
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  Paper,
  TextField
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Language } from "@mui/icons-material";
import { fetchDocBotInstances } from "../redux/features/botsSlice";
import { useAppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

export default function CreateBotModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const token = Cookies.get("access_token") ?? "";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [name, setName] = React.useState("");
  const [botLanguage, setBotLanguage] = React.useState("Turkish");
  const [description, setDescription] = React.useState("");
  const [domainName, setDomainName] = React.useState("");
  const [isPublic, setIsPublic] = React.useState("true");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<null | any[]>(null);
  const dispatch = useAppDispatch();
  const createBotInstance = async () => {
    setErrors(null);
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/docBot`,
        {
          name: name.length > 0 ? name : null,
          BotLanguage: botLanguage.length > 0 ? botLanguage : null,
          ChatModel: "GPT-3.5-turbo",
          title: name.length > 0 ? name : null,
          subdomain: domainName.length > 0 ? domainName : null,
          is_public: isPublic === "true" ? true : false,
          description: description.length > 0 ? description : null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === "success") {
        dispatch(fetchDocBotInstances({ token, BASE_URL }));
        handleClose();
      }

      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate("/login");
      }
      setLoading(false);
      if (error) {
        setErrors(error?.response?.data?.errors);
      }
    }
  };

  return (
    <div>
      <CreateButton title="Create Bot Instance" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <React.Fragment>
          <CssBaseline />
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography variant="h6" gutterBottom>
                Create Document Bot Instance
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="Name"
                    name="Name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="domainName"
                    name="domainName"
                    label="Domain Name"
                    fullWidth
                    variant="standard"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    variant="standard"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Language
                    </InputLabel>
                    <NativeSelect
                      required
                      value={botLanguage}
                      onChange={(e) => setBotLanguage(e.target.value)}
                      inputProps={{
                        name: "language",
                        id: "uncontrolled-native"
                      }}
                    >
                      <option value={"Turkish"}>Turkish</option>
                      <option value={"English"}>English</option>
                      <option value={"German"}>German</option>
                      <option value={"French"}>French</option>
                      <option value={"Spanish"}>Spanish</option>
                      <option value={"Italian"}>Italian</option>
                      <option value={"Portuguese"}>Portuguese</option>
                      <option value={"Russian"}>Russian</option>
                      <option value={"Japanese"}>Japanese</option>
                      <option value={"Korean"}>Korean</option>
                      <option value={"Chinese"}>Chinese</option>
                      <option value={"Arabic"}>Arabic</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Visibility
                    </InputLabel>
                    <NativeSelect
                      required
                      value={isPublic}
                      onChange={(e) => setIsPublic(e.target.value)}
                      inputProps={{
                        name: "visibility",
                        id: "uncontrolled-native"
                      }}
                    >
                      <option value={"true"}>Public</option>
                      <option value={"false"}>Private</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <div className="flex flex-col px-5 my-2">
                  {errors &&
                    errors?.map((error) => (
                      <p className="text-red-500 text-base font-medium">
                        {error.message}
                      </p>
                    ))}
                </div>
                <div className="flex space-x-2 justify-end w-full mt-4">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                      setErrors(null);
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={createBotInstance}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                  >
                    <span>CREATE</span>
                  </LoadingButton>
                </div>
              </Grid>
            </Paper>
          </Container>
        </React.Fragment>
      </Modal>
    </div>
  );
}
