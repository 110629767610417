import Cookies from "js-cookie";
import React, { useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Box, Button, Modal, Typography } from "@mui/material";
import axios from "axios";
import { fetchDocBotInstances } from "../redux/features/botsSlice";
import { useAppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";

function DeleteBotModal({ id }: { id: string }) {
  const token = Cookies.get("access_token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteBot = async (instanceId: string) => {
    setError(null);
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/docBot/${instanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === "success") {
        dispatch(fetchDocBotInstances({ token, BASE_URL }));

        handleClose();
      } else {
        setError("Hata oluştu");
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  return (
    <div>
      <DeleteRoundedIcon
        onClick={(e) => {
          e.preventDefault();

          handleOpen();
        }}
        fontSize="medium"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Botunuz silinecek.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Botu silmeniz durumunda yüklü tüm dosyalarınız silinecek, botunuza
            ve botunuzun yönetici sayfasına ulaşamıyor olacaksınız. Silmek
            istedinizden emin misiniz?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {error && (
              <p className="text-red-500 text-base font-semibold">{error}</p>
            )}
          </Typography>
          <div className="flex space-x-2 justify-end w-full mt-4">
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                setError(null);
                handleClose();
              }}
            >
              Vazgeç
            </Button>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => deleteBot(id)}
              color="error"
            >
              Sil
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteBotModal;
