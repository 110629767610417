import React from "react";

function JoinCere() {
  return (
    <div className="flex w-1/2 mx-auto flex-col rounded-t-lg py-5 ">
      <div className="flex flex-col space-y-4  mx-auto bg-gradient-to-t from-rose-600 to-gray-600 py-5">
        <h1 className="text-white text-2xl font-bold text-center">
          Join the Generative AI Revolution
        </h1>
        <p className="text-white text-lg font-medium text-center w-2/3 mx-auto">
          Thousands of businesses worldwide are using Chaindesk Generative AI
          platform to solve business specific use-cases. Don't get left behind -
          start building your own custom AI chatbot today!
        </p>
        <div className="bg-white rounded-md mx-auto px-2 py-1 cursor-pointer">
          <h1 className="text-black text-base font-semibold">
            Get Started Now!
          </h1>
        </div>
      </div>
    </div>
  );
}

export default JoinCere;
