import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BotDetail from "./BotDetail";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { Box, Button, Link, Modal } from "@mui/material";
import CreateBotModal from "./CreateBotModal";
import axios from "axios";
import Cookies from "js-cookie";
import DeleteBotModal from "./DeleteBotModal";
import { fetchDocBotInstances } from "../redux/features/botsSlice";
export default function UserBots() {
  const token = Cookies.get("access_token") ?? "";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const docBotInstances = useSelector(
    (state: RootState) => state.bots.docBotInstances
  );
  React.useEffect(() => {
    setRefreshCounter(refreshCounter + 1);
  }, [docBotInstances]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="flex flex-col space-y-4">
      <div>
        {docBotInstances.length > 0 ? (
          docBotInstances.map((instance) => (
            <Accordion
              key={instance.id}
              expanded={expanded === instance.id}
              onChange={handleChange(instance.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="flex justify-between flex-grow ">
                  <Typography sx={{ flexShrink: 0 }}>
                    <p className="text-xl font-semibold">{instance.name}</p>
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    <Link
                      color="inherit"
                      target="_blank"
                      rel="noopener"
                      href={`https://${instance.subdomain}.cereinsight.com`}
                      underline="none"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="text-lg font-medium">
                        {instance.subdomain}.cereinsight.com
                      </p>
                    </Link>
                  </Typography>
                  <div className="flex items-center justify-center space-x-2 pr-5">
                    <Link
                      color="inherit"
                      target="_blank"
                      rel="noopener"
                      href={`https://${instance.subdomain}.cereinsight.com/admin`}
                      underline="none"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <SupervisorAccountIcon fontSize="medium" />
                    </Link>
                    <DeleteBotModal id={instance.id} />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <BotDetail
                  name={instance.name}
                  subdomain={instance.subdomain}
                  language={instance.language}
                  chatModel={instance.chat_model}
                  description={instance.description}
                  monthlyUsage={instance.token_spent}
                  isPublic={instance.is_public}
                />
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <h1 className="text-lg text-center font-medium text-white">
            There is no bot instance to display
          </h1>
        )}
      </div>
      <div>
        <CreateBotModal />
      </div>
    </div>
  );
}
