import React from "react";
import cerebrum from "../assets/cerebrum-logo.png";
import cere from "../assets/cere.png";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

function Poweredby({ ideas }: any) {
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const handleClickIdeas = () => {
    ideas.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className=" items-center justify-center my-4 hidden sm:flex">
      <div className="border border-white w-1/2 flex items-center justify-center lg:justify-between px-4 pt-2 rounded-lg bg-gradient-to-r from-red-400 via-gray-300 to-blue-500">
        <div className="cursor-pointer flex flex-col space-y-1 w-1/4">
          <h1 className="text-white text-xs md:text-base md:text-semibold pl-2">
            Powered by
          </h1>
          <img src={cerebrum} alt="cerebrum" className="w-3/4 h-16" />
        </div>
        <div className="w-2/4 cursor-pointer flex flex-row space-x-2 items-center">
          <img src={cere} alt="cere" className="w-[150px] h-[200px]" />
          <h1 className="w-full text-white font-semibold text-base md:text-xl">
            We seek the knowledge that
            <br /> we yet to discover shape the future
          </h1>
        </div>
        <Link
          color="inherit"
          target="_blank"
          rel="noopener"
          href="https://cerebrumtechnologies.com/"
          underline="none"
        >
          <div className="bg-gradient-to-r from-yellow-600 to-red-600 rounded-lg hidden lg:flex items-center justify-center cursor-pointer">
            <h1 className="text-white font-semibold px-4 py-1  ">Learn More</h1>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Poweredby;
