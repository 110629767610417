import React from "react";

interface BotDetailProps {
  name: null | string;
  subdomain: null | string;
  language: null | string;
  description: null | string;
  isPublic: null | boolean;
  monthlyUsage: null | number | string;
  chatModel: null | string;
}

function BotDetail({
  name,
  subdomain,
  language,
  description,
  isPublic,
  monthlyUsage,
  chatModel
}: BotDetailProps) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center">
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Name: </h1>
          </div>
          <h1 className="text-lg font-medium">{name}</h1>
        </div>
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Domain: </h1>
          </div>
          <h1 className="text-lg font-medium">{subdomain}.cereinsight.com</h1>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Language: </h1>
          </div>
          <h1 className="text-lg font-medium">{language}</h1>
        </div>
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Chat Model: </h1>
          </div>
          <h1 className="text-lg font-medium">{chatModel}</h1>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Monthly Usage: </h1>
          </div>
          <h1 className="text-lg font-medium">{monthlyUsage}</h1>
        </div>
        <div className="flex space-x-2 items-center w-1/2">
          <div>
            <h1 className=" text-lg font-semibold">Visibility: </h1>
          </div>
          <h1 className="text-lg font-medium">
            {isPublic ? "Public" : "Private"}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default BotDetail;
