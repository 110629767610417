import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UserBots from "../components/UserBots";
import { useAppDispatch } from "../redux/store";
import { fetchDocBotInstances } from "../redux/features/botsSlice";
import Cookies from "js-cookie";

function BotOperationsPage() {
  const token = Cookies.get("access_token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDocBotInstances({ token, BASE_URL }));
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-t from-gray-700 via-gray-900 to-black">
      <div className="flex flex-col space-y-8 flex-grow">
        <Navbar />
        <div className="flex flex-col space-y-2 w-1/2 mx-auto">
          <h1 className="text-white text-2xl font-semibold">Botlarım</h1>
          <UserBots />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BotOperationsPage;
