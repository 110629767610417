import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { CardCover } from "@mui/joy";
import logo from "../assets/beyazcerelogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { useSignOut } from "react-auth-kit";
import { setIsLoggedIn } from "../redux/features/userSlice";

function Navbar({ pricingRef }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signOutFromApp = useSignOut();
  const signOut = () => {
    sessionStorage.removeItem("destinationRoute");
    dispatch(setIsLoggedIn(false));
    signOutFromApp();
  };
  const page = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickPricing = () => {
    if (page.pathname.length > 1) {
      navigate("/");
    } else {
      pricingRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  const user = useSelector((state: RootState) => state.user.user);

  const navigateToLogin = (destinationRoute: any) => {
    sessionStorage.setItem("destinationRoute", destinationRoute);
    navigate(destinationRoute);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "black" }}>
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
          className="flex justify-between"
        >
          <Link to="/">
            <div className="flex space-x-2 items-center cursor-pointer">
              <img src={logo} alt="Cere" className="w-14" />

              <div className="flex flex-col -space-y-1 items-center justify-center text-white text-xl font-bold">
                <h1>Cere</h1>
                <h1>Insights</h1>
              </div>
            </div>
          </Link>

          <div className="flex items-center justify-center space-x-4">
            <Link to="/managebot">
              <h1
                //onClick={() => navigateToLogin("/managebot")}
                className="text-white text-lg font-medium cursor-pointer "
              >
                Create Bot
              </h1>
            </Link>
            <h1
              className="text-white text-lg font-medium cursor-pointer"
              onClick={handleClickPricing}
            >
              Pricing
            </h1>
            <h1 className="text-white text-lg font-medium cursor-pointer">
              Watch Demo
            </h1>
          </div>

          {isLoggedIn ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={user}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user ?? ""} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Üyelik İptal</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Hesap Sil</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Şifre Değiştir</Typography>
                </MenuItem>

                <MenuItem onClick={signOut}>
                  <Typography textAlign="center">Çıkış Yap</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Link to="/login">
              <button className="border border-white rounded-full px-2 py-1 hover:bg-white hover:text-black">
                Sign in
              </button>
            </Link>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
