import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface botsSliceState {
  docBotInstances: any[];
  docBotLoading: boolean;
}

const initialState: botsSliceState = {
  docBotLoading: false,
  docBotInstances: []
};

export const fetchDocBotInstances = createAsyncThunk(
  "bots/docBotInstances",
  async ({ token, BASE_URL }: any) => {
    const response = await axios.get(`${BASE_URL}/api/docBot`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data.data.docBotInstances;
  }
);

export const botsSlice = createSlice({
  name: "bots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocBotInstances.pending, (state, { payload }) => {
      state.docBotLoading = true;
    });
    builder.addCase(fetchDocBotInstances.fulfilled, (state, { payload }) => {
      state.docBotInstances = payload;
      state.docBotLoading = false;
    });
    builder.addCase(fetchDocBotInstances.rejected, (state, { payload }) => {
      state.docBotLoading = false;
    });
  }
});

export const {} = botsSlice.actions;

export default botsSlice.reducer;
