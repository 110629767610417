import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
function CreateButton({ title, onClick }: { title: string; onClick: any }) {
  return (
    <div
      onClick={() => onClick()}
      className="flex space-x-2 px-2 py-1 items-center justify-center bg-transparent border border-gray-400 hover:bg-white rounded-md w-1/2 mx-auto cursor-pointer"
    >
      <AddCircleOutlineIcon fontSize="large" color="primary" />
      <h1 className="text-[#1976d2] text-lg font-semibold">{title}</h1>
    </div>
  );
}

export default CreateButton;
