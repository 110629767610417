import React from "react";
import cere from "../assets/cere2.png";
import app from "../assets/app.png";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HtmlIcon from "@mui/icons-material/Html";
import { BsFiletypeDocx, BsFiletypeDoc, BsFiletypePpt } from "react-icons/bs";
import { TbHtml } from "react-icons/tb";
import { FaFilePdf, FaFileCsv } from "react-icons/fa";
import { setSubscriptionType } from "../redux/features/checkoutSlice";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
function HeroSection() {
  return (
    <div className="flex mx-auto w-2/3 justify-between px-10 mb-5">
      <div className="flex flex-col justify-between space-y-4 w-5/12 items-center ">
        <img src={cere} alt="cere" className="w-7/12" />
        <div className="flex flex-col space-y-1">
          <p className="text-rose-600 font-medium text-xl">Build your own</p>
          <p className="text-white font-semibold text-4xl">Cere Document Bot</p>
          <p className="text-white font-semibold text-4xl">
            Trained On Your Custom Data
          </p>
          <p className="text-white font-medium text-lg">
            With our no-code platform, you can create a custom AI chatbot
            trained on your data in seconds. Streamline customer support,
            onboard new team members, and more!
          </p>
        </div>
        <div className="flex w-full items-center justify-start space-x-2">
          <div className="flex items-center space-x-2 justify-center rounded-md border border-gray-400 px-2 py-1 cursor-pointer  hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-rose-500 to-rose-600">
            <AdsClickIcon className="text-white" />

            <h1 className="text-lg text-white font-semibold ">
              Get Started Now
            </h1>
          </div>
          <div className="flex items-center space-x-2 justify-center rounded-md border border-gray-400 px-2 py-1 cursor-pointer  hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-rose-500 to-rose-600">
            <PermPhoneMsgIcon className="text-white" />

            <h1 className="text-lg text-white font-semibold ">Book a Call</h1>
          </div>
        </div>
      </div>
      <div className="flex w-7/12 space-x-4 justify-end rounded-md">
        <div className="flex flex-col justify-between w-1/6">
          <div className="flex justify-end">
            <FaFilePdf color="white" size={40} />
          </div>
          <div className="flex justify-start">
            <FaFileCsv color="white" size={40} />
          </div>
          <div className="flex justify-end">
            <BsFiletypeDoc color="white" size={40} />
          </div>
          <div className="flex justify-start">
            <TbHtml color="white" size={40} />
          </div>
          <div className="flex justify-end">
            <BsFiletypeDocx color="white" size={40} />
          </div>
          <div className="flex justify-start">
            <BsFiletypePpt color="white" size={40} />
          </div>
        </div>
        <img src={app} alt="" className="w-2/3 rounded-md mx-auto" />
      </div>
    </div>
  );
}

export default HeroSection;
