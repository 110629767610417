import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";
import HeroSection from "../components/HeroSection";
import Information from "../components/Information";
import Poweredby from "../components/PoweredBy";
import FAQ from "../components/FAQ";
import JoinCere from "../components/JoinCere";
import axios from "axios";
import { useAppDispatch } from "../redux/store";
import { setPricing, setUserLocation } from "../redux/features/userSlice";

function LandingPage() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const pricingRef = useRef(null);
  const dispatch = useAppDispatch();

  async function fetchUserLocation() {
    try {
      const response = await axios.get("https://ipapi.co/json");
      const data = response.data;
      dispatch(setUserLocation(data.country_code));
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  }

  const getPrices = async () => {
    const response = await axios.get(`${BASE_URL}/api/prices/subscriptions`);
    dispatch(setPricing(response.data.data.subscriptions[1].pricingPlans));
    dispatch(
      setPricing({
        monthUSD: response.data.data.subscriptions[1].pricingPlans[3].price,
        mounthTRY: response.data.data.subscriptions[1].pricingPlans[1].price,
        yearUSD: response.data.data.subscriptions[1].pricingPlans[2].price,
        yearTRY: response.data.data.subscriptions[1].pricingPlans[0].price
      })
    );
  };

  useEffect(() => {
    //fetchUserLocation();
    getPrices();
  }, []);
  return (
    <div className="flex min-h-screen flex-col bg-gradient-to-t from-gray-700 via-gray-900 to-black">
      <div className="flex flex-col space-y-8 flex-grow">
        <Navbar pricingRef={pricingRef} />
        <HeroSection />
        <Information />
        <Poweredby />

        <Pricing pricingRef={pricingRef} />
        <FAQ />
        <JoinCere />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
