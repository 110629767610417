import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {
  setSubscriberCardNumber,
  setSubscriberCvv,
  setSubscriberExpiryDate,
  setSubscriberNameOnCard
} from "../redux/features/checkoutSlice";

export default function PaymentForm() {
  const dispatch = useAppDispatch();
  const nameOnCard = useSelector(
    (state: RootState) => state.checkout.nameOnCard
  );
  const cardNumber = useSelector(
    (state: RootState) => state.checkout.cardNumber
  );
  const expireDate = useSelector(
    (state: RootState) => state.checkout.expiryDate
  );
  console.log(expireDate);

  const cvv = useSelector((state: RootState) => state.checkout.cvv);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            type="text"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            value={nameOnCard}
            onChange={(e) => dispatch(setSubscriberNameOnCard(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            type="number"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            value={cardNumber}
            onChange={(e) => dispatch(setSubscriberCardNumber(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            type="month"
            autoComplete="cc-exp"
            variant="standard"
            value={expireDate}
            onChange={(e) => dispatch(setSubscriberExpiryDate(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            type="number"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            value={cvv}
            onChange={(e) => dispatch(setSubscriberCvv(e.target.value))}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
