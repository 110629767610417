import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "user",
  storage,
  whitelist: [
    "user",
    "company",
    "token",
    "pricing",
    "userLocation",
    "isLoggedIn"
  ]
};

interface userSliceState {
  user: null | string;
  company: null | string;
  token: null | string;
  isLoggedIn: boolean;
  pricing: Object | any;
  userLocation: string;
}

const initialState: userSliceState = {
  user: null,
  company: null,
  token: null,
  isLoggedIn: false,
  pricing: {
    monthUSD: "",
    mounthTRY: "",
    yearUSD: "",
    yearTRY: ""
  },
  userLocation: "TR"
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, { payload }) => {
      state.user = payload;
    },
    setCompanyName: (state, { payload }) => {
      state.company = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setPricing: (state, { payload }) => {
      state.pricing = payload;
    },
    setUserLocation: (state, { payload }) => {
      state.userLocation = payload;
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    }
  },
  extraReducers: (builder) => {}
});

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export const {
  setUserName,
  setCompanyName,
  setToken,
  setPricing,
  setUserLocation,
  setIsLoggedIn
} = userSlice.actions;

export default persistedReducer;
