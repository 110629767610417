import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";
const defaultTheme = createTheme();

export default function Pricing({ pricingRef }: any) {
  const { t, i18n } = useTranslation();
  const [subsChanger, setSubsChanger] = React.useState("month");
  const pricing = useSelector((state: RootState) => state.user.pricing);
  const location = useSelector((state: RootState) => state.user.userLocation);
  const navigate = useNavigate();

  const navigateToLogin = (destinationRoute: any) => {
    sessionStorage.setItem("destinationRoute", destinationRoute);
    navigate(destinationRoute);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      {/* Hero unit */}
      <Container
        ref={pricingRef}
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 4, pb: 3 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ color: "white" }}
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
          sx={{ color: "white" }}
        >
          Choose one of our membership packages below and start experiencing
          your own Cere Document Bot!
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid
          container
          spacing={5}
          alignItems="flex-end"
          style={{ alignItems: "end", justifyContent: "center" }}
        >
          <Grid item key={"Pro"} xs={12} sm={6} md={5}>
            <Card>
              <CardHeader
                title={"Pro"}
                subheader={"Most Popular"}
                titleTypographyProps={{ align: "center" }}
                action={<StarBorderIcon />}
                subheaderTypographyProps={{
                  align: "center"
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700]
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2
                  }}
                >
                  {
                    <div className="flex items-center space-x-2">
                      {subsChanger === "year" && (
                        <h1 className="text-base font-semibold">Yearly</h1>
                      )}
                      <CalendarMonthIcon
                        onClick={() => {
                          if (subsChanger === "year") {
                            setSubsChanger("month");
                          } else if (subsChanger === "month") {
                            setSubsChanger("year");
                          }
                        }}
                        className="border cursor-pointer border-gray-400 rounded-full p-1"
                        fontSize="large"
                      />
                      {subsChanger === "month" && (
                        <h1 className="text-base font-semibold">Monthly</h1>
                      )}
                    </div>
                  }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    {subsChanger === "month"
                      ? `$${pricing.monthUSD ?? pricing.monthUSD}`
                      : `$${pricing.yearUSD ?? pricing.yearUSD}`}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    {subsChanger === "month" ? "/mo" : "/year"}
                  </Typography>
                </Box>
                <ul>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.pro.text1")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.pro.text2")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.pro.text3")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.pro.text4")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.pro.text5")}
                  </Typography>
                </ul>
              </CardContent>
              <Link
                color="inherit"
                underline="none"
                rel="noopener"
                href="/checkout"
              >
                <CardActions>
                  <Button
                    fullWidth
                    variant={true && "outlined"}
                    onClick={() => navigateToLogin("/checkout")}
                  >
                    {t("components.pricing.pro.buttonText")}
                  </Button>
                </CardActions>
              </Link>
            </Card>
          </Grid>

          <Grid item key={"Enterprise"} xs={12} sm={12} md={5}>
            <Card>
              <CardHeader
                title={"Enterprise"}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{
                  align: "center"
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700]
                }}
              />
              <CardContent>
                <ul>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text1")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text2")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text3")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text4")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text5")}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {t("components.pricing.enterprise.text6")}
                  </Typography>
                </ul>
              </CardContent>
              <Link
                color="inherit"
                underline="none"
                rel="noopener"
                href="/contactus"
              >
                <CardActions>
                  <Button fullWidth variant={"contained"}>
                    {t("components.pricing.enterprise.buttonText")}
                  </Button>
                </CardActions>
              </Link>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
