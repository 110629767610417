import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function ContactUsPage() {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-t from-gray-700 via-gray-900 to-black">
      <div className="flex flex-col space-y-8 flex-grow">
        <Navbar />
      </div>
      <Footer />
    </div>
  );
}

export default ContactUsPage;
