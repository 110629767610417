import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface checkoutSliceState {
  subscriptionType: null | string;
  subscriberFirstName: null | string;
  subscriberLastName: null | string;
  subscriberAddress: null | string;
  subscriberPhone: string;
  city: null | string;
  postCode: null | string;
  country: null | string;
  paymentAddressCheck: boolean;
  nameOnCard: null | string;
  cardNumber: null | string;
  expiryDate: null | string;
  cvv: null | string;
}

const initialState: checkoutSliceState = {
  subscriptionType: "pro-month",
  subscriberFirstName: "",
  subscriberLastName: "",
  subscriberAddress: "",
  subscriberPhone: "",
  city: "",
  postCode: "",
  country: "",
  paymentAddressCheck: false,
  nameOnCard: "",
  cardNumber: "",
  expiryDate: "2030-01",
  cvv: ""
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setSubscriptionType: (state, action: PayloadAction<string>) => {
      state.subscriptionType = action.payload;
    },
    setSubscriberFirstName: (state, action: PayloadAction<string>) => {
      state.subscriberFirstName = action.payload;
    },
    setSubscriberLastName: (state, action: PayloadAction<string>) => {
      state.subscriberLastName = action.payload;
    },
    setSubscriberAddress: (state, action: PayloadAction<string>) => {
      state.subscriberAddress = action.payload;
    },
    setSubscriberPhone: (state, action: PayloadAction<string>) => {
      state.subscriberPhone = action.payload;
    },
    setSubscriberCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setSubscriberPostCode: (state, action: PayloadAction<string>) => {
      state.postCode = action.payload;
    },
    setSubscriberCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setSubscriberPaymentAddressCheck: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.paymentAddressCheck = action.payload;
    },
    setSubscriberNameOnCard: (state, action: PayloadAction<string>) => {
      state.nameOnCard = action.payload;
    },
    setSubscriberCardNumber: (state, action: PayloadAction<string>) => {
      state.cardNumber = action.payload;
    },
    setSubscriberExpiryDate: (state, action: PayloadAction<string>) => {
      state.expiryDate = action.payload;
    },
    setSubscriberCvv: (state, action: PayloadAction<string>) => {
      state.cvv = action.payload;
    }
  },
  extraReducers: (builder) => {}
});

export const {
  setSubscriptionType,
  setSubscriberFirstName,
  setSubscriberLastName,
  setSubscriberAddress,
  setSubscriberPhone,
  setSubscriberCity,
  setSubscriberPostCode,
  setSubscriberCountry,
  setSubscriberPaymentAddressCheck,
  setSubscriberNameOnCard,
  setSubscriberCardNumber,
  setSubscriberExpiryDate,
  setSubscriberCvv
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
